import { Flex } from 'theme-ui';
import SubMenuItems from './sub-menu-items';
import SubMenuOptions from './sub-menu-options';

const UserDetailsOptions = ({ state, updateState, subMenuType, toggleSubMenu }) => (
    <>
        <Flex sx={{ justifyContent: 'space-between' }}>
            <SubMenuOptions
                entity="users"
                uid={state.user_id}
                subMenuType={subMenuType}
                toggleSubMenu={toggleSubMenu}
                items={[
                    { title: 'Personal', subMenuGroup: 'personal' },
                    { title: 'Currency', subMenuGroup: 'currency' },
                ]}
            />
        </Flex>

        {subMenuType === 'personal' && (
            <SubMenuItems
                entity="users"
                uid={state.user_id}
                state={state}
                updateState={updateState}
                toggleSubMenu={toggleSubMenu}
                items={[
                    {
                        activeTab: 'personal',
                        activeTabGroup: 'personal',
                        title: 'Details',
                    },
                    {
                        activeTab: 'assigned_users',
                        activeTabGroup: 'personal',
                        title: 'Users assigned',
                    },
                ]}
            />
        )}
        {subMenuType === 'currency' && (
            <SubMenuItems
                entity="users"
                uid={state.user_id}
                state={state}
                updateState={updateState}
                toggleSubMenu={toggleSubMenu}
                items={[
                    {
                        activeTab: 'trades',
                        activeTabGroup: 'currency',
                        title: 'Trades',
                    },
                    {
                        activeTab: 'deposits',
                        activeTabGroup: 'currency',
                        title: 'Deposits',
                    },
                    {
                        activeTab: 'credit_card_payments',
                        activeTabGroup: 'currency',
                        title: 'Credit Card Payments',
                    },
                    {
                        activeTab: 'withdrawals',
                        activeTabGroup: 'currency',
                        title: 'Withdrawals',
                    },
                    {
                        activeTab: 'changes',
                        activeTabGroup: 'currency',
                        title: 'Comments',
                    },
                ]}
            />
        )}
    </>
);

export default UserDetailsOptions;
