/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { Paragraph, Flex, Box, Image } from 'theme-ui';
import { useState } from 'react';
import { getUsers } from '../utils/pagination';
import labelMapping from '../utils/label-mapping';

const RowWrapper = (props: any) => {
    if (props.href) {
        return (
            <a target={props.openInNewTab ? '_blank' : '_self'} style={{ textDecoration: 'none' }} href={props.href}>
                {props.children}
            </a>
        );
    }

    return props.children;
};

// @ts-ignore
export const Title = ({
    title = '',
    type = '',
    state = {},
    // @ts-ignore
    updateState,
    showEditIcon = true,
}) => (
    <Paragraph
        sx={{
            fontSize: '18px',
            lineHeight: '20px',
            color: '#000',
            fontWeight: '600',
            borderBottom: '1px solid #AAA',
            paddingBottom: '5px',
            marginRight: 50,
            position: 'relative',
        }}
    >
        {title}
        {showEditIcon && (
            <i
                style={{
                    marginLeft: '10px',
                    fontSize: '12px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '3px',
                }}
                className="fa fa-pencil"
                aria-hidden="true"
                onClick={() =>
                    updateState({
                        ...state,
                        showUpdateOverlay: true,
                        updateOverlayType: type,
                    })
                }
            />
        )}
    </Paragraph>
);

export const DisplayItem = ({
    title = '',
    value = '',
    type = '',
    sx = {},
    valueSx = {},
    required = false,
    linkToPage = false,
    pageUrl = '',
}) => (
    <Flex
        sx={{
            minWidth: '120px',
            flexDirection: 'column',
            marginTop: '25px',
            marginRight: '25px',
            textAlign: 'left',
            ...(sx || {}),
        }}
    >
        <Paragraph sx={{ fontSize: '12px', minHeight: 30, fontWeight: 600 }}>{title}</Paragraph>

        {type === 'kyc' && (
            <Box
                sx={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '100px',
                    marginTop: '10px',
                    backgroundColor: value ? 'green' : 'red',
                }}
            />
        )}
        <RowWrapper href={linkToPage ? pageUrl : null}>
            {type !== 'kyc' && (
                <Paragraph
                    sx={{
                        fontSize: '14px',
                        color: required && value === '' ? 'red' : linkToPage ? 'linkColor' : '#1C1C1C',
                        marginTop: '10px',
                        fontWeight: '500',
                        cursor: linkToPage ? 'pointer' : 'default',
                        // textDecoration: linkToPage ? 'underline' : 'unset',
                        ...(valueSx || {}),
                    }}
                >
                    {!required ? `${value}` : `${value === '' ? 'required' : value}`}
                </Paragraph>
            )}
        </RowWrapper>
    </Flex>
);

export const TableHeaderItem = ({ text, topLeft = false, topRight = false }: any) => (
    <Box
        sx={{
            border: '1px solid #09042B',
            flex: 1,
            textAlign: 'center',
            fontSize: '13px',
            fontWeight: '600',
            lineHeight: '35px',
            padding: '0 10px',
            borderTopLeftRadius: topLeft ? '12px' : '',
            borderTopRightRadius: topRight ? '12px' : '',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: '#09042B',
        }}
    >
        {text}
    </Box>
);

export const TableHeader = () => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
            color: '#fff',
        }}
    >
        <TableHeaderItem text="ID" topLeft />
        <TableHeaderItem text="Email" />
        <TableHeaderItem text="" topRight />
    </Flex>
);

export const ClientTableHeader = () => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '40px',
            color: '#fff',
        }}
    >
        <TableHeaderItem text="Name" topLeft />
        <TableHeaderItem text="Type" />
        <TableHeaderItem text="Stage" />
        <TableHeaderItem text="" topRight />
    </Flex>
);

export const TableRowItem = ({
    text,
    action = () => {},
    type = '',
    expandRow = false,
    expandTypeText = false,
    large = false,
    openInNewTab = false,
    href,
    copyText = false,
}: any) => {
    const [showMore, updateShowMore] = useState(false);
    const [copied, updateCopyState] = useState(false);

    const copyValue = () => {
        updateCopyState(true);
        const theText = text.replace(/(\r\n|\n|\r)/gm, '');
        navigator.clipboard.writeText(theText);
        setTimeout(() => {
            updateCopyState(false);
        }, 1000);
    };

    let fontSize = showMore && expandTypeText ? '13px' : '13px';

    if (large) {
        fontSize = '50px';
    }

    return (
        <Flex
            sx={{
                borderBottom: '1px solid #F1F1F1',
                flex: 1,
                textAlign: 'center',
                lineHeight: '20px',
                padding: '20px',
                background: '#FFF',
                width: '90%',
                justifyContent: 'center',
                position: 'relative',
                minHeight: showMore ? '140px' : '90px',
                cursor: type === 'view' || expandTypeText ? 'pointer' : 'default',
            }}
            onClick={() => {
                if (!expandTypeText) {
                    action();
                }
            }}
        >
            <RowWrapper openInNewTab={openInNewTab} href={href}>
                <Paragraph
                    sx={{
                        color: copied ? 'green' : type === 'view' ? '#5100C9' : '#002033',
                        fontSize,
                        fontWeight: 400,
                        textAlign: expandTypeText ? 'left' : 'center',
                        width: type === 'view' ? 100 : '100%',
                        position: 'relative',
                    }}
                >
                    {expandRow && !copied
                        ? `${showMore && expandTypeText ? text : text.substring(0, 50)} ${showMore ? '' : '...'}`
                        : copied
                        ? 'copied to clipboard'
                        : labelMapping(text)}

                    {expandTypeText && (
                        <i
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                position: 'absolute',
                                right: '-20px',
                                top: '5px',
                                cursor: 'pointer',
                                color: '#002033',
                            }}
                            className={`fal fa-chevron-${showMore ? 'up' : 'down'}`}
                            onClick={() => {
                                if (expandTypeText) {
                                    updateShowMore(!showMore);
                                }
                            }}
                        />
                    )}
                    {copyText && (
                        <i
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                position: 'absolute',
                                right: '0px',
                                top: '5px',
                                cursor: 'pointer',
                                color: '#002033',
                            }}
                            className="fal fa-copy"
                            onClick={() => {
                                if (copyText) {
                                    copyValue();
                                }
                            }}
                        />
                    )}
                </Paragraph>
            </RowWrapper>
        </Flex>
    );
};

export const TableRow = ({ user, i, isCorporate }: any) => (
    <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', height: '100px' }}>
        <TableRowItem text={user.user_id} />
        <TableRowItem text={user.email} />
        <TableRowItem
            openInNewTab
            type="view"
            text="View"
            href={`/users/${user.user_id}?is_corporate=${!!isCorporate}`}
        />
    </Flex>
);

export const ClientTableRow = ({ client, i }: any) => (
    <Flex key={i} sx={{ justifyContent: 'space-between', width: '100%', height: '100px' }}>
        <TableRowItem text={client.first_name ? `${client.first_name} ${client.last_name}` : client.company_name} />
        <TableRowItem text={client.type} />
        <TableRowItem text={client.stage} />
        <TableRowItem openInNewTab type="view" text="View" href={`/clients/${client.uuid}`} />
    </Flex>
);

export const Pagination = ({ state, updateState, dataFetchFunction = getUsers }: any) => (
    <Flex sx={{ width: '100px', margin: '20px auto', justifyContent: 'space-around' }}>
        {state.pageNumber > 1 && (
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() => dataFetchFunction({ ...state, pageNumber: state.pageNumber - 1 }, updateState)}
            >
                <Image
                    src="https://cdn.blocklender.io/arrows/enabled-left.png"
                    width="26"
                    height="26"
                    alt="prev button"
                />
            </Box>
        )}

        {state.pageNumber <= 1 && (
            <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                <Image
                    src="https://cdn.blocklender.io/arrows/disabled-left.png"
                    width="26"
                    height="26"
                    alt="prev button"
                />
            </Box>
        )}

        <Paragraph sx={{ fontWeight: '600', fontSize: '13px' }}>{state?.pageNumber}</Paragraph>

        {state?.paginationMap?.[state.pageNumber + 1] && (
            <Box
                sx={{ width: '26px', height: '26px', cursor: 'pointer' }}
                onClick={() => dataFetchFunction({ ...state, pageNumber: state.pageNumber + 1 }, updateState, 'next')}
            >
                <Image
                    src="https://cdn.blocklender.io/arrows/enabled-right.png"
                    width="26"
                    height="26"
                    alt="next button"
                />
            </Box>
        )}

        {!state.paginationMap?.[state.pageNumber + 1] && (
            <Box sx={{ width: '26px', height: '26px', cursor: 'not-allowed' }}>
                <Image
                    src="https://cdn.blocklender.io/arrows/disabled-right.png"
                    width="26"
                    height="26"
                    alt="next button"
                />
            </Box>
        )}
    </Flex>
);
