/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button } from 'theme-ui';
import moment from 'moment';
import labelMapping from '../../utils/label-mapping';

export const Row = ({ label = '', value = '' }) => (
    <Flex>
        <Paragraph sx={{ fontSize: 14, mr: 20, fontWeight: 800, mb: 10, width: 180 }}>{label}:</Paragraph>
        <Paragraph sx={{ fontSize: 14, width: 400, overflowWrap: 'break-word' }}>{value}</Paragraph>
    </Flex>
);

const CreditCardPayment = ({ creditCardPayment, close }) => {
    console.log(creditCardPayment);
    return (
        <Flex sx={{ ml: 50, mt: 60, flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Payment Details</Paragraph>

            <Row label="Timestamp" value={moment.utc(creditCardPayment.created_at).format('Do MMMM YYYY HH:ss')} />

            <Row label="Status" value={labelMapping(creditCardPayment.status)} />

            <Row label="Session Id" value={creditCardPayment.checkout_session_id} />

            <Row label="Currency" value={labelMapping(creditCardPayment.data?.currency)} />
            <Row label="Gross" value={new Intl.NumberFormat('en-US', {}).format(creditCardPayment.data?.gross)} />
            <Row label="Net" value={new Intl.NumberFormat('en-US', {}).format(creditCardPayment.data?.net)} />
            <Row label="Number of credits" value={creditCardPayment.data?.numberOfCreditsThisBuys} />
            <Row label="Price" value={new Intl.NumberFormat('en-US', {}).format(creditCardPayment.data?.price)} />
            <Row label="product" value={labelMapping(creditCardPayment.data?.product)} />

            <Button onClick={close} sx={{ alignSelf: 'center', position: 'absolute', bottom: 30, width: 150 }}>
                OK
            </Button>
        </Flex>
    );
};

export default CreditCardPayment;
