/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
import { Flex, Paragraph, Box, Button } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { useEffect, useState } from 'react';
import TabGroup from '../components/detail-views/tab-group-combined';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import Overlay from '../components/overlay';
import BasicDetails, { WalletBalances } from '../components/basic-details';
import AccountSummary from '../components/account-summary';
import TransactionsList from '../components/transactions-list';
import buttonStyle from '../utils/button-style';
import { Title } from '../components/table';
import CreditAccount from '../components/credit-account';
import AddCorporateUser from '../components/corporates/add-user';
import SimpleAlert from '../components/simple-alert';

const defaultState = {};

const resend = async (item, currentState, updateState) => {
    try {
        updateState({ ...currentState, loading: true });

        await axios({
            url: `${process.env.REACT_APP_USER_DATA_API}/admin-tools`,
            method: 'POST',
            data: {
                tool: 'assign_to_corporate',
                corporate_id: item.user_id,
                first_name: 'foo',
                last_name: 'foo',
                new_email_address: item.email,
                resend: true,
            },
        });

        updateState({
            ...currentState,
            loading: false,
            alertConfig: {
                success: true,
                text: 'Successfully resent',
            },
        });
    } catch (e) {
        updateState({
            ...currentState,
            alertConfig: {
                success: false,
                text: 'Uh oh, time to contact support or try again',
            },
            loading: false,
        });
    }
};

const fetchUserData = async (id, state, updateState, history) => {
    try {
        await Auth.currentAuthenticatedUser();

        updateState({ ...state, loading: true });

        const [{ data }, { data: accountSummary }, { data: corporateRecord }] = await Promise.all([
            axios({
                url: `${process.env.REACT_APP_USER_DATA_API}/admin/${id}/detail`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
            }),
            axios({
                url: `${process.env.REACT_APP_USER_DATA_API}/admin/${id}/summary`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
            }),
            state.isCorporate
                ? axios({
                      url: `${process.env.REACT_APP_USER_DATA_API}/corporates/${id}`,
                      headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                          'Cache-Control': 'no-cache',
                      },
                  })
                : Promise.resolve({ data: null }),
        ]);

        updateState({
            ...state,
            ...data,
            accountSummary,
            corporateRecord,
        });
        return data;
    } catch (e) {
        history.push('/');
    }
};

const Users = ({ logOut, memberships }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [state, updateState] = useState({
        ...defaultState,
        isCorporate: queryParams.get('is_corporate') === 'true',
        activeTab: queryParams.get('activeTab') || 'personal',
        activeTabGroup: queryParams.get('activeTabGroup') || 'personal',
    });
    const [showMenu, toggleMenu] = useState(true);
    const [subMenu, toggleSubMenu] = useState({ show: false, type: null });

    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            await fetchUserData(id, state, updateState, history);
        })();
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
                position: 'relative',
            }}
        >
            {subMenu?.show && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        top: 0,
                        left: 0,
                        zIndex: 9999,
                    }}
                />
            )}
            <Sidebar
                logout={logOut}
                currentPage="Users"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
                memberships={memberships}
            />

            {state.showCreditAccountDialog && (
                <Overlay
                    closeOverlay={() => updateState({ ...state, showCreditAccountDialog: false })}
                    overlayContent={<CreditAccount state={state} userId={state.user_id} />}
                />
            )}

            {state.showAddUserOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...state, showAddUserOverlay: false })}
                    overlayContent={
                        <AddCorporateUser
                            closeOverlay={() => {
                                updateState({ ...state, showAddUserOverlay: false });
                            }}
                            corporateId={state.user_id}
                        />
                    }
                />
            )}

            {state.alertConfig && (
                <Overlay
                    size="small"
                    closeOverlay={() => updateState({ ...state, alertConfig: null })}
                    overlayContent={<SimpleAlert {...state.alertConfig} />}
                />
            )}

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '20px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                {state.error && (
                    <Overlay
                        size="small"
                        closeOverlay={() => updateState({ ...state, error: null })}
                        overlayContent={
                            <Paragraph
                                sx={{
                                    ml: 20,
                                    mr: 20,
                                    color: 'red',
                                    mt: 130,
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                {state.error}
                            </Paragraph>
                        }
                    />
                )}
                <Flex sx={{ mb: 20 }}>
                    <Flex sx={{ mt: '10px', cursor: 'pointer' }} onClick={() => history.push('/users')}>
                        <i sx={{ marginRight: '10px' }} className="fas fa-arrow-left" />
                    </Flex>
                </Flex>

                <Flex
                    sx={{
                        position: 'relative',
                        mb: -30,
                    }}
                >
                    <TabGroup
                        state={state}
                        updateState={updateState}
                        subMenu={subMenu}
                        toggleSubMenu={toggleSubMenu}
                        memberships={memberships}
                        tabs={[
                            {
                                id: 'personal',
                                label: 'Personal',
                                icon: 'far fa-users',
                            },
                            {
                                id: 'currency',
                                label: 'Currency',
                                icon: 'far fa-credit-card',
                            },
                        ]}
                    />
                </Flex>

                <Flex sx={{ flexDirection: 'column' }}>
                    {state.activeTab === 'personal' && (
                        <Flex
                            sx={{
                                width: '100%',
                                borderRadius: 12,
                                mt: 20,
                                padding: 20,
                                height: 'auto',
                                backgroundColor: 'white',
                                flexDirection: 'column',
                            }}
                        >
                            <Box sx={{ mt: 20 }} />
                            <Paragraph sx={{ fontWeight: '800', ml: 12, mb: 40, fontSize: 24 }}>
                                {state.isCorporate ? 'Corporate Record' : 'User Record'}
                            </Paragraph>
                            <BasicDetails state={state} updateState={updateState} />
                            <WalletBalances state={state} updateState={updateState} />
                            <AccountSummary state={state} updateState={updateState} />

                            <Box sx={{ width: '100%', marginLeft: '15px', mb: '60px' }}>
                                <Title title="Additional Tools" showEditIcon={false} />
                                <Flex sx={{ flexWrap: 'wrap', mt: '10px', ml: '0px' }}>
                                    <Button
                                        onClick={() => updateState({ ...state, showCreditAccountDialog: true })}
                                        sx={buttonStyle}
                                    >
                                        Credit Account
                                    </Button>
                                </Flex>
                            </Box>

                            {state.corporateRecord && (
                                <Box sx={{ width: '100%', marginLeft: '15px', mb: '60px' }}>
                                    <Title title="Corporate Tools" showEditIcon={false} />
                                    <Flex sx={{ flexWrap: 'wrap', mt: '10px', ml: '20px' }}>
                                        <Button
                                            onClick={() => updateState({ ...state, showAddUserOverlay: true })}
                                            sx={buttonStyle}
                                        >
                                            Add User
                                        </Button>
                                    </Flex>
                                </Box>
                            )}
                        </Flex>
                    )}

                    {state.activeTab === 'trades' && (
                        <Flex sx={{ flexDirection: 'column', backgroundColor: '#FFF', mt: 20 }}>
                            <Paragraph sx={{ ml: 40, mt: 20, mb: -20, zIndex: 10, fontSize: 20, fontWeight: '800' }}>
                                Trades
                            </Paragraph>
                            <TransactionsList
                                disableAbilityToExpand
                                expanded
                                title="Trades"
                                type="trades"
                                userId={id}
                            />
                        </Flex>
                    )}

                    {state.activeTab === 'withdrawals' && (
                        <Flex sx={{ flexDirection: 'column', backgroundColor: '#FFF', mt: 20 }}>
                            <Paragraph sx={{ ml: 40, mt: 20, mb: -20, zIndex: 10, fontSize: 20, fontWeight: '800' }}>
                                Withdrawals
                            </Paragraph>
                            <TransactionsList
                                disableAbilityToExpand
                                expanded
                                title="Withdrawals"
                                type="withdrawals"
                                userId={id}
                            />
                        </Flex>
                    )}

                    {state.activeTab === 'changes' && (
                        <TransactionsList expanded title="Comments" type="changes" userId={id} />
                    )}

                    {state.activeTab === 'deposits' && (
                        <Flex sx={{ flexDirection: 'column', backgroundColor: '#FFF', mt: 20 }}>
                            <Paragraph sx={{ ml: 40, mt: 20, mb: -20, zIndex: 10, fontSize: 20, fontWeight: '800' }}>
                                Deposits
                            </Paragraph>

                            <TransactionsList
                                expanded
                                disableAbilityToExpand
                                title="Deposits"
                                type="deposits"
                                userId={id}
                            />
                        </Flex>
                    )}

                    {state.activeTab === 'credit_card_payments' && (
                        <Flex sx={{ flexDirection: 'column', backgroundColor: '#FFF', mt: 20 }}>
                            <Paragraph sx={{ ml: 40, mt: 20, mb: -20, zIndex: 10, fontSize: 20, fontWeight: '800' }}>
                                Credit Card Payments
                            </Paragraph>

                            <TransactionsList expanded disableAbilityToExpand type="credit_card_payments" userId={id} />
                        </Flex>
                    )}

                    {state.activeTab === 'assigned_users' && (
                        <TransactionsList
                            title="Users"
                            expanded
                            type="corporateUsers"
                            userId={id}
                            additionalLinkColumns={[
                                {
                                    label: 'Resend invite',
                                    action: (item) => resend(item, state, updateState),
                                },
                                {
                                    label: 'View',
                                    action: (item) => window.location.assign(`/users/${item.user_id}`),
                                },
                            ]}
                        />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, memberships: account.memberships };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
