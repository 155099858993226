/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unreachable */
/* eslint-disable no-throw-literal */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import * as axios from 'axios';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import { Box, Spinner, Paragraph, Flex, Button, Select } from 'theme-ui';
import { connect } from 'react-redux';
import { TableHeaderItem } from './table';
import labelMapping from '../utils/label-mapping';
import TransactionRow from './transactions-row';
import AddComment from './changes/add-comment';
import Overlay from './overlay';
import TransactionsPagination from './pagination/transactions';
import DepositDetailView from './detail-views/deposit';
import CreditCardPaymentDetailView from './detail-views/credit-card-payment';

import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';
import { getComments, getCorporateUsers } from '../utils/pagination';
import CommentsPagination from './pagination/comments';

const tableDefinition = {
    trades: {
        headers: ['Product', 'Type', 'Amount', 'Currency', 'Created at'],
        cells: ['product', 'type', 'amount', 'currency', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount'],
    },
    deposits: {
        headers: ['Amount', 'Currency', 'Created at'],
        cells: ['amount', 'currency', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount'],
        showViewMoreColumn: true,
    },
    withdrawals: {
        headers: ['Amount', 'Currency', 'Created at'],
        cells: ['amount', 'currency', 'created_at'],
        timestampColumns: ['created_at'],
        formatAsCurrency: ['amount'],
    },
    credit_card_payments: {
        headers: ['Status', 'Created at'],
        cells: ['status', 'created_at'],
        timestampColumns: ['created_at'],
        showViewMoreColumn: true,
    },
    corporateUsers: {
        headers: ['User id', 'Email address'],
        cells: ['user_id', 'email'],
    },
    changes: {
        headers: ['Comment', 'Created at'],
        cells: ['reason', 'created_at'],
        timestampColumns: ['created_at'],
    },
};

export const getMonitoringAlerts = async (
    state,
    updateState,
    direction = 'next',
    paginationData,
    setPaginationData
) => {
    updateState({
        ...state,
        loading: true,
    });

    const pageArray = [...paginationData.paginationArray];

    let pageNumber;
    let token;

    if (direction === 'start') {
        pageNumber = paginationData.pageNumber;
        token = pageArray[pageNumber];
    } else if (direction === 'next') {
        pageNumber = paginationData.pageNumber + 1;
        token = pageArray[pageNumber];
    } else {
        pageNumber = paginationData.pageNumber - 1;
        token = pageArray[pageNumber];
    }

    const { data } = await axios({
        url: process.env.REACT_APP_TX_MONITORING_API,
        method: 'POST',
        data: {
            query: `query { 
                adminSearchMonitoringChecks(user_id: "${state.userId}", limit: 10, ${
                token !== '' ? `pagination_token: "${token}"` : ''
            }) { 
                    success
                    checks {
                        alerts {
                            type
                            description
                        }
                        user_id
                        timestamp
                        status
                    }
                    pagination_token
                }
            }`,
            variables: null,
        },
    });

    const result = data.data?.adminSearchMonitoringChecks;

    if (['next', 'start'].includes(direction)) {
        pageArray[pageNumber + 1] = result?.pagination_token;
    }

    const newPaginationData = {
        paginationArray: pageArray,
        pageNumber,
    };

    updateState({
        ...state,
        items: result?.checks.map((check) => ({ ...check, count: check.alerts.length })),
        loading: false,
        type: 'monitoring',
    });

    setPaginationData(newPaginationData);

    return result;
};

const typeMapping = {
    credit_card_payments: {
        route: 'credit-card-payments',
        listKey: 'payments',
    },
};

const loadFigures = async (currentState, updateState, type, paginationData, setPaginationData, dataCallback) => {
    updateState({ ...currentState, loading: true });

    if (type === 'changes') {
        try {
            return await getComments(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            return updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the comments',
            });
        }
    }
    if (type === 'corporateUsers') {
        try {
            return await getCorporateUsers(currentState, updateState, 'start', paginationData, setPaginationData);
        } catch (e) {
            return updateState({
                ...currentState,
                loading: false,
                error: 'Failed to get the comments',
            });
        }
    }

    try {
        const { data } = await axios({
            url: `${process.env.REACT_APP_USER_DATA_API}/admin/${currentState.userId}/${
                typeMapping[type]?.route || type
            }`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        });

        const list = data[typeMapping[type]?.listKey || type];

        updateState({
            ...currentState,
            loading: false,
            error: false,
            items: list,
        });

        if (dataCallback) {
            dataCallback(list);
        }
    } catch (e) {
        console.log(e);
        updateState({
            ...currentState,
            loading: false,
            error: 'Uh oh, there has been an error loading the figures',
        });
    }
};

const TransactionsList = (props) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: null,
        type: props.type,
        embeddedIn: props.embeddedIn,
        rebalancingGroupId: props.rebalancingGroupId,
        userId: props.userId,
        limit: 10,
        commentsLimit: props.commentsLimit || 10,
        offset: 0,
        items: props.type === 'referredUsers' ? props.list : [],
        pageNumber: 1,
        showAddComment: false,
        manualComment: '',
        manualCommentError: false,
        showDepositOverlay: false,
        showCreditCardPaymentOverlay: false,
        manualCommentErrorMsg: '',
        expanded: props.expanded || false,
        fromDate: null,
        toDate: null,
        deposit: null,
        mixedFundTrade: null,
        withdrawal: null,
        currency: null,
        product: null,
    });

    useEffect(() => {
        if (currentState.expanded) {
            loadFigures(
                currentState,
                updateState,
                props.type,
                props.paginationData,
                props.setPaginationData,
                props.dataCallback
            );
        }
    }, [currentState.expanded]);

    useEffect(() => {
        if (currentState.expanded) {
            loadFigures(
                currentState,
                updateState,
                props.type,
                props.paginationData,
                props.setPaginationData,
                props.dataCallback
            );
        }
    }, [currentState.pageNumber, currentState.expanded]);

    if (!currentState.expanded) {
        return (
            <Box sx={{ mb: 20, borderRadius: 12, background: '#FFFFFF' }}>
                <Flex
                    sx={{
                        padding: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => updateState({ ...currentState, expanded: true })}
                >
                    <Paragraph sx={{ fontWeight: '800', fontSize: 18 }}>{props.title}</Paragraph>
                    <i
                        style={{
                            fontSize: '13px',
                            fontWeight: 600,
                            cursor: 'pointer',
                            marginTop: 5,
                        }}
                        className="fal fa-chevron-down"
                    />
                </Flex>
            </Box>
        );
    }

    return (
        <Box sx={{ mb: 20, borderRadius: 12, background: '#FFFFFF', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            {currentState.showDepositOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showDepositOverlay: false })}
                    overlayContent={
                        <DepositDetailView
                            deposit={currentState.deposit}
                            close={() => updateState({ ...currentState, showDepositOverlay: false })}
                        />
                    }
                />
            )}

            {currentState.showCreditCardPaymentOverlay && (
                <Overlay
                    closeOverlay={() => updateState({ ...currentState, showCreditCardPaymentOverlay: false })}
                    overlayContent={
                        <CreditCardPaymentDetailView
                            creditCardPayment={currentState.creditCardPayment}
                            close={() => updateState({ ...currentState, showCreditCardPaymentOverlay: false })}
                        />
                    }
                />
            )}

            {!currentState.expanded && (
                <Flex
                    sx={{
                        padding: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => updateState({ ...currentState, expanded: true })}
                >
                    <Paragraph sx={{ fontWeight: '800', fontSize: 18 }}>{props.title}</Paragraph>
                    <i
                        style={{
                            fontSize: '13px',
                            fontWeight: 600,
                            cursor: 'pointer',
                            marginTop: 5,
                        }}
                        className="fal fa-chevron-down"
                    />
                </Flex>
            )}

            {currentState.expanded && (
                <Box sx={{ padding: 40 }}>
                    {currentState.loading && <Spinner sx={{ position: 'absolute', ml: 100, mt: -14 }} />}

                    <>
                        <Flex
                            sx={{
                                justifyContent: 'space-between',
                                flexDirection: props?.type === 'changes' ? 'row' : 'column',
                            }}
                        >
                            {!props.disableAbilityToExpand && (
                                <Flex
                                    onClick={() =>
                                        !props.hideExpandChevron && updateState({ ...currentState, expanded: false })
                                    }
                                >
                                    <Paragraph sx={{ mb: 20, mr: 20, fontWeight: '800', fontSize: 18 }}>
                                        {props.title}
                                    </Paragraph>
                                    {!props.hideExpandChevron && (
                                        <i
                                            style={{
                                                fontSize: '13px',
                                                fontWeight: 600,
                                                cursor: 'pointer',
                                                marginTop: 5,
                                            }}
                                            className="fal fa-chevron-up"
                                        />
                                    )}
                                </Flex>
                            )}

                            {currentState.error && (
                                <Paragraph sx={{ mb: 20, color: 'red' }}>{currentState.error}</Paragraph>
                            )}

                            {props.showDatePickers && (
                                <Flex
                                    sx={{
                                        mt: 20,
                                        mb: 40,
                                        maxWidth: 900,
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Flex>
                                        <Paragraph sx={{ mr: 20, mt: 10, width: 160 }}>From: </Paragraph>
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            selected={currentState.fromDate}
                                            onChange={(date) =>
                                                updateState({
                                                    ...currentState,
                                                    fromDate: date,
                                                    toDate: currentState.toDate || date,
                                                })
                                            }
                                        />
                                    </Flex>
                                    <Flex>
                                        <Paragraph sx={{ mr: 20, mt: 10 }}>To: </Paragraph>
                                        <DatePicker
                                            showTimeSelect
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            selected={currentState.toDate}
                                            onChange={(date) => updateState({ ...currentState, toDate: date })}
                                        />
                                    </Flex>

                                    {props.showCurrencyDropDown && (
                                        <Flex sx={{ mt: 40 }}>
                                            <Paragraph sx={{ mr: 40, mt: 12, width: 80 }}>Currency: </Paragraph>
                                            <Select
                                                sx={{ width: 300 }}
                                                value={currentState.currency}
                                                onChange={(e) => {
                                                    updateState({ ...currentState, currency: e.target.value });
                                                }}
                                            >
                                                <option value="">Select a currency</option>
                                                <option value="eur">Euros</option>
                                                <option value="dai">DAI</option>
                                                <option value="bitcoin">Bitcoin</option>
                                                <option value="ethereum">Ethereum</option>
                                                <option value="tether">USDT</option>
                                                <option value="usd-coin">USDC</option>
                                            </Select>
                                        </Flex>
                                    )}

                                    {props.showProductDropDown && (
                                        <Flex sx={{ mt: 30 }}>
                                            <Paragraph sx={{ mr: 40, mt: 12, width: 80 }}>Product: </Paragraph>
                                            <Select
                                                sx={{ width: 300 }}
                                                value={currentState.product}
                                                onChange={(e) => {
                                                    updateState({ ...currentState, product: e.target.value });
                                                }}
                                            >
                                                <option value="">Select a product</option>
                                                <option value="auto_eth">Ethereum</option>
                                                <option value="auto_btc">Bitcoin</option>
                                                <option value="stablecoin">Stablecoin</option>
                                            </Select>
                                        </Flex>
                                    )}

                                    <Flex sx={{ mt: 70 }}>
                                        <Button
                                            onClick={() =>
                                                loadFigures(
                                                    currentState,
                                                    updateState,
                                                    props.type,
                                                    props.paginationData,
                                                    props.setPaginationData,
                                                    props.dataCallback
                                                )
                                            }
                                            sx={{ ml: 20, width: 200, height: 40, mt: -10 }}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                updateState({ ...currentState, fromDate: null, toDate: null });
                                                loadFigures(
                                                    { ...currentState, fromDate: null, toDate: null, offset: 0 },
                                                    updateState,
                                                    props.type,
                                                    props.paginationData,
                                                    props.setPaginationData,
                                                    props.dataCallback
                                                );
                                            }}
                                            sx={{ ml: 20, width: 200, height: 40, mt: -10 }}
                                        >
                                            Clear
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}

                            {props?.type === 'changes' && (
                                <Paragraph
                                    sx={{ color: '#09042B', cursor: 'pointer' }}
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            showAddComment: !currentState.showAddComment,
                                            manualComment: '',
                                            manualCommentError: false,
                                            manualCommentErrorMsg: '',
                                        })
                                    }
                                >
                                    {currentState.showAddComment ? 'Close' : 'Add a comment'}
                                </Paragraph>
                            )}
                        </Flex>

                        {currentState.showAddComment && (
                            <AddComment
                                close={() => updateState({ ...currentState, showAddComment: false })}
                                reload={() =>
                                    loadFigures(
                                        { ...currentState, showAddComment: false },
                                        updateState,
                                        props.type,
                                        props.paginationData,
                                        props.setPaginationData,
                                        props.dataCallback
                                    )
                                }
                                currentState={currentState}
                                updateState={updateState}
                            />
                        )}

                        <Flex
                            sx={{
                                justifyContent: 'space-between',
                                width: '100%',
                                height: '40px',
                                color: '#fff',
                            }}
                        >
                            {tableDefinition[currentState.type]?.headers?.map((header) => (
                                <TableHeaderItem key={`${currentState.type}_${header}`} text={header} />
                            ))}

                            {(tableDefinition[currentState.type]?.showViewMoreColumn || props.openUserOnViewMore) && (
                                <TableHeaderItem key={`${currentState.type}_viewmore`} text="" />
                            )}

                            {props.additionalLinkColumns?.map((col, idx) => (
                                <TableHeaderItem key={`${currentState.type}_viewmore_${idx}`} text="" />
                            ))}
                        </Flex>

                        {props?.type === 'changes' && (
                            <TransactionRow
                                items={currentState.items}
                                tableDefinition={tableDefinition}
                                currentState={currentState}
                                labelMapping={labelMapping}
                            />
                        )}

                        {props.type === 'changes' && currentState.items?.length > 1 && (
                            <CommentsPagination
                                currentState={currentState}
                                updateState={updateState}
                                paginationData={props.paginationData}
                                setPaginationData={props.setPaginationData}
                            />
                        )}

                        {props?.type !== 'changes' && (
                            <TransactionRow
                                currentState={currentState}
                                labelMapping={labelMapping}
                                tableDefinition={tableDefinition}
                                expandable={currentState.type === 'monitoring'}
                                updateState={updateState}
                                openUserOnViewMore={props.openUserOnViewMore}
                                additionalLinkColumns={props.additionalLinkColumns}
                                action={(item) => {
                                    if (currentState.type === 'deposits') {
                                        updateState({ ...currentState, showDepositOverlay: true, deposit: item });
                                    }

                                    if (currentState.type === 'credit_card_payments') {
                                        updateState({
                                            ...currentState,
                                            showCreditCardPaymentOverlay: true,
                                            creditCardPayment: item,
                                        });
                                    }

                                    if (currentState.type === 'trades' && props.openUserOnViewMore) {
                                        window.location.assign(`/user/${item.user_id}`);
                                    }

                                    if (currentState.type === 'withdrawals') {
                                        updateState({
                                            ...currentState,
                                            showWithdrawalOverlay: true,
                                            withdrawal: item,
                                        });
                                    }
                                }}
                            />
                        )}

                        {['withdrawals', 'deposits', 'trades', 'credit_card_payments'].includes(props.type) &&
                            currentState.items?.length > 0 && (
                                <Flex sx={{ width: '200px', margin: '20px auto', justifyContent: 'space-around' }}>
                                    <TransactionsPagination
                                        currentState={currentState}
                                        updateState={updateState}
                                        totalPages={Math.ceil(
                                            currentState.total ? currentState.total / currentState.limit : 1
                                        )}
                                        totalRecords={currentState.total}
                                    />
                                </Flex>
                            )}
                    </>
                </Box>
            )}
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setPaginationData: (paginationData) => dispatch({ type: 'SET_COMMENT_PAGINATION_DATA', paginationData }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { paginationData: account.CommentpaginationData };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
