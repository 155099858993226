/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button } from 'theme-ui';
import moment from 'moment';
import labelMapping from '../../utils/label-mapping';

export const Row = ({ label = '', value = '' }) => (
    <Flex>
        <Paragraph sx={{ fontSize: 14, mr: 20, fontWeight: 800, mb: 10, width: 180 }}>{label}:</Paragraph>
        <Paragraph sx={{ fontSize: 14, width: 400, overflowWrap: 'break-word' }}>{value}</Paragraph>
    </Flex>
);

const Deposit = ({ deposit, close }) => (
    <Flex sx={{ ml: 50, mt: 60, flexDirection: 'column' }}>
        <Paragraph sx={{ fontWeight: '800', mb: 20 }}>Deposit Details</Paragraph>

        <Row label="Amount" value={new Intl.NumberFormat('en-US', {}).format(deposit.amount)} />

        <Row label="Timestamp" value={moment.utc(deposit.created_at).format('Do MMMM YYYY HH:ss')} />

        <Row label="Currency" value={labelMapping(deposit.currency)} />

        <Row label="Deposit Type" value={labelMapping(deposit.type)} />

        {!deposit.fiat_details && <Row label="Source Address" value={deposit.source_address} />}

        {deposit.fiat_details && (
            <>
                <Paragraph sx={{ fontWeight: '800', mt: 30, mb: 20 }}>Beneficiary</Paragraph>

                <Row label="Sender Name" value={deposit.fiat_details?.sender_name} />
                <Row label="Sender Address" value={deposit.fiat_details?.sender_address} />

                {deposit.fiat_details?.sender_iban && (
                    <Row label="Sender IBAN" value={deposit.fiat_details?.sender_iban} />
                )}
                {deposit.fiat_details?.sender_account_number && (
                    <Row label="Sender Account Number" value={deposit.fiat_details?.sender_account_number} />
                )}
                {deposit.fiat_details?.sender_routing_codes?.length && (
                    <Row
                        label="Sender Sort Code"
                        value={deposit.fiat_details?.sender_routing_codes?.[0]?.routingCodeValue || ''}
                    />
                )}
                <Row label="Payment Type" value={deposit.fiat_details?.payment_type} />
                <Row label="Id" value={deposit.fiat_details?.id} />
                <Row label="Transaction Id" value={deposit.fiat_details?.transaction_id} />
            </>
        )}

        <Button onClick={close} sx={{ alignSelf: 'center', position: 'absolute', bottom: 30, width: 150 }}>
            OK
        </Button>
    </Flex>
);

export default Deposit;
