/* eslint-disable react/no-array-index-key */
import { Flex } from "theme-ui";
import { formatting } from "@bybrix/shared-ui-libs";
import * as moment from "moment";
import { TableRowItem } from "./table";

const ChangesRow = ({
  currentState,
  labelMapping,
  tableDefinition,
  action,
  openUserOnViewMore,
  additionalLinkColumns,
}) =>
  currentState.items?.length ? (
    currentState.items?.map((item, i) => (
      <Flex
        key={`${item.reason}-${i}`}
        sx={{
          justifyContent: "space-between",
          width: "100%",
          height: currentState.type === "devices" ? "auto" : "65px",
        }}
      >
        {tableDefinition[currentState.type]?.cells.map((cell) => {
          let cellText = labelMapping(item[cell]);
          if (
            cellText &&
            tableDefinition[currentState.type]?.timestampColumns?.includes(cell)
          ) {
            cellText = moment
              .utc(cellText, "YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]")
              .local()
              .format("DD MMM YY HH:mm");
          }
          if (
            cellText &&
            tableDefinition[currentState.type]?.formatAsCurrency?.includes(cell)
          ) {
            cellText = formatting.formatForDisplay(cellText);
          }
          return (
            <TableRowItem
              key={`${currentState.type}_${cell}`}
              text={cellText}
            />
          );
        })}

        {additionalLinkColumns?.map((linkColumn) => (
          <TableRowItem
            action={() => linkColumn.action(item)}
            key={`${currentState.type}_${linkColumn.label}`}
            type="view"
            text={linkColumn.label}
          />
        ))}

        {(tableDefinition[currentState.type]?.showViewMoreColumn ||
          openUserOnViewMore) && (
          <TableRowItem
            action={() => action && action(item)}
            key={`${currentState.type}_viewmore`}
            type="view"
            text="View"
          />
        )}
      </Flex>
    ))
  ) : (
    <div />
  );

export default ChangesRow;
