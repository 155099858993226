import { Auth } from 'aws-amplify';
import { debounce } from 'debounce';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Flex, Input, Paragraph } from 'theme-ui';
import { searchForCorporates } from '../components/advanced-search';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { Pagination, TableHeader, TableRow } from '../components/table';

const Corporates = ({ logOut }) => {
    const [showMenu, toggleMenu] = useState(true);
    const [state, updateState] = useState({
        corporates: [],
        pageNumber: 1,
        offset: 0,
        paginationMap: { 1: '' },
        filters: {},
    });

    const history = useHistory();

    const getUsersDebounced = useMemo(
        () =>
            debounce(async (e) => {
                const newState = {
                    ...state,
                    filters: { ...state.filters, email: e?.target?.value },
                };

                updateState(newState);

                await searchForCorporates(newState, updateState);
            }, 500),
        []
    );

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();

                await searchForCorporates(state, updateState);
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await searchForCorporates(state, updateState);
        })();
    }, [state.pageNumber]);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Corporates"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                <>
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}> Corporate Records</Paragraph>

                    <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Input
                            onChange={getUsersDebounced}
                            sx={{ width: 400, mb: 20, background: '#FFFFFF' }}
                            placeholder="Search by email"
                        />
                    </Flex>

                    <TableHeader />

                    {state?.corporates?.map((corporate, i) => (
                        <TableRow
                            isCorporate
                            user={corporate}
                            i={i}
                            key={`${corporate.user_id}-row`}
                            history={history}
                        />
                    ))}

                    <Pagination
                        state={state}
                        updateState={updateState}
                        dataFetchFunction={async (newState) => searchForCorporates(newState, updateState)}
                    />
                </>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
    updateState: (usersListState) => dispatch({ type: 'SET_USERS_STATE', usersListState }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Corporates);
