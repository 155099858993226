/** @jsxImportSource theme-ui */
import { Flex, Image, Box } from 'theme-ui';
import { connect } from 'react-redux';
import { logUserOut } from '../utils/logout';

const items = [
    {
        name: 'Users',
        icon: 'far fa-users',
        url: '/users',
    },
    { name: 'Corporates', icon: 'far fa-city', url: '/corporates' },
    {
        name: 'Pricing',
        icon: 'far fa-credit-card',
        url: '/pricing',
    },
    {
        name: 'Sign out',
        icon: 'far fa-sign-out-alt',
        url: '/login',
        logout: true,
    },
];

const returnMenuItems = (currentPage: string, logout: any, history: any) => {
    const menuItems = items.map((item, i) => (
        <Flex
            sx={{
                width: 200,
                color: '#FFF',
                backgroundColor: currentPage === item.name ? 'rgba(255,255,255,0.3)' : '#09042B',
                borderLeftColor: currentPage === item.name ? '#FFF' : '#09042B',
                height: 40,
                '&:hover': {
                    borderLeftColor: '#FFF',
                    backgroundColor: 'rgba(255,255,255,0.3)',
                },
                borderLeftWidth: '5px',
                borderLeftStyle: 'solid',
                alignItems: 'center',
                fontFamily: 'heading',
                cursor: 'pointer',
                mb: 20,
            }}
            key={`${item.name}`}
            onClick={async () => {
                if (item.logout) {
                    logUserOut(history, logout);
                } else {
                    window.location.assign(`${item.url}`);
                }
            }}
        >
            <i sx={{ fontSize: 18, mr: 15, ml: 20, color: 'white', mt: '-5px' }} className={`${item.icon}`} />
            {item.name}
        </Flex>
    ));

    return <Flex sx={{ flexDirection: 'column', mt: 10 }}>{menuItems}</Flex>;
};

const SidebarComponent = (props: any) => (
    <Flex>
        <Flex
            sx={{
                position: 'fixed',
                top: 20,
                left: -10,
                display: 'block',
                zIndex: 1001,
                cursor: 'pointer',
            }}
            onClick={() => {
                props.toggleMenu(!props.showMenu);
            }}
        >
            <i
                sx={{
                    fontSize: 24,
                    mr: 15,
                    ml: 20,
                    color: props.showMenu ? 'white' : 'black',
                    mt: '-5px',
                }}
                className="fas fa-bars"
            />
        </Flex>
        <Flex
            sx={{
                width: 200,
                height: '100%',
                position: 'fixed',
                top: 0,
                backgroundColor: '#09042B',
                flexDirection: 'column',
                boxShadow: ' 0px 0 5px 4px rgba(0,0,0,0.1)',
                transition: 'all 0.3s linear',
                left: props.showMenu ? '0' : '-300px',
                zIndex: 1000,
            }}
        >
            <Box
                sx={{
                    width: 89,
                    height: 35,
                    mt: 20,
                    ml: 'auto',
                    mr: 'auto',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => window.location.assign('/users')}
            >
                <Image
                    src="https://cdn.accru.finance/logos/lce/carbon-logo-light6.png"
                    width="89"
                    height="35"
                    alt="logo"
                />
            </Box>
            <br />
            {returnMenuItems(props.currentPage, props.logout, props.history)}
        </Flex>
    </Flex>
);

const mapStateToProps = (state: any) => {
    const { account } = state;
    return { memberships: account.memberships };
};

export default connect(mapStateToProps, null)(SidebarComponent);
