/* eslint-disable no-unused-vars */
// import axios from 'axios';
import { useState } from 'react';
import { Flex, Paragraph, Button, Heading, Message } from 'theme-ui';
import axios from 'axios';
import Spinner from '../spinner';
import Input from '../form-input';

const addNewUser = async (currentState: any, updateState: any, updateErrorState: any) => {
    updateState({ ...currentState, loading: true });

    const errors = {
        firstName: true,
        lastName: true,
        email: true,
    };

    errors.firstName = currentState.firstName === '';
    errors.lastName = currentState.lastName === '';
    errors.email = currentState.email === '';
    updateErrorState({ ...errors });

    if (errors.firstName || errors.lastName || errors.email) {
        updateState({ ...currentState, loading: false });
        return;
    }

    await axios({
        url: `${process.env.REACT_APP_USER_DATA_API}/admin-tools`,
        method: 'POST',
        data: {
            tool: 'assign_to_corporate',
            corporate_id: currentState.corporateId,
            first_name: currentState.firstName,
            last_name: currentState.lastName,
            new_email_address: currentState.email,
        },
    });

    window.location.reload();
};

const AddCorporateUser = (props: any) => {
    const [currentState, updateState] = useState({
        loading: false,
        error: null,
        corporateId: props.corporateId,
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        showCloseButton: false,
    });

    const [errorState, updateErrorState] = useState({
        firstName: false,
        lastName: false,
        email: false,
    });

    return (
        <Flex sx={{ mt: 70, ml: 20, mr: 20, flexDirection: 'column' }}>
            {currentState.loading && <Spinner />}
            <Heading sx={{ mb: 30, mt: '-20px' }}>Add new corporate user</Heading>

            <Input
                sx={{
                    mb: '12px',
                    alignSelf: 'center',
                    width: '100%',
                    background: '#FFFFFF',
                    border: '1px solid grey',
                    opacity: 0.6,
                    cursor: 'not-allowed',
                }}
                type="text"
                disabled
                id="corporateId"
                data-testid="corporateId"
                name="corporateId"
                label="Corporate Id"
                placeholder="corporate Id"
                onChange={(e: any) => updateState({ ...currentState, corporateId: e.target.value })}
                error={null}
                value={currentState.corporateId}
            />
            <Input
                sx={{
                    mb: '12px',
                    alignSelf: 'center',
                    width: '100%',
                    background: '#FFFFFF',
                    border: errorState.firstName ? '1px solid red' : '1px solid grey',
                }}
                type="text"
                id="firstName"
                data-testid="firstName"
                name="firstName"
                label="First name"
                placeholder="enter first name"
                onChange={(e: any) => updateState({ ...currentState, firstName: e.target.value })}
                error={null}
                value={currentState.firstName}
            />
            <Input
                sx={{
                    mb: '12px',
                    alignSelf: 'center',
                    width: '100%',
                    background: '#FFFFFF',
                    border: errorState.lastName ? '1px solid red' : '1px solid grey',
                }}
                type="text"
                id="lastName"
                data-testid="lastName"
                name="lastName"
                label="Last name"
                placeholder="enter last name"
                onChange={(e: any) => updateState({ ...currentState, lastName: e.target.value })}
                error={null}
                value={currentState.lastName}
            />
            <Input
                sx={{
                    mb: '12px',
                    alignSelf: 'center',
                    width: '100%',
                    background: '#FFFFFF',
                    border: errorState.email ? '1px solid red' : '1px solid grey',
                }}
                type="email"
                id="email"
                data-testid="email"
                name="email"
                label="Email"
                placeholder="enter email address"
                onChange={(e: any) => updateState({ ...currentState, email: e.target.value })}
                error={null}
                value={currentState.email}
            />

            {currentState.error && <Paragraph sx={{ color: 'red', mb: 20 }}>{currentState.error}</Paragraph>}
            {currentState.message && (
                <Paragraph sx={{ mb: '5px', mt: '15px', ml: '5px', textAlign: 'center', color: 'red' }}>
                    {currentState.message}
                </Paragraph>
            )}

            {!currentState.showCloseButton && (
                <Button onClick={() => addNewUser(currentState, updateState, updateErrorState)} sx={{ mb: 30, mt: 30 }}>
                    Add user
                </Button>
            )}

            {currentState.showCloseButton && (
                <Button onClick={() => props.closeOverlay()} sx={{ mb: 30, mt: 30 }}>
                    Close
                </Button>
            )}
        </Flex>
    );
};

export default AddCorporateUser;
