const textMapping = {
    gbp: 'GBP',
    eur: 'EUR',
    solar: 'Solar',
    wind: 'Wind',
    forestry: 'Forestry',
};

// @ts-ignore
export default (key: string) => textMapping[key] || key || '';
