/* eslint-disable no-unused-vars */
import { Box, Button, Flex } from 'theme-ui';
import UserDetailsOptions from '../submenu-options/user-details';

const tabButton = (active) => ({
    backgroundColor: 'inherit',
    borderRadius: 0,
    border: 'none',
    borderLeft: '1px solid #DCDCDC',
    borderBottom: '1px solid #DCDCDC',
    outline: 'none',
    cursor: 'pointer',
    padding: '13px 7px',
    transition: '0.3s',
    fontSize: '14px',
    color: active ? '#FFF' : '#09042B',
    '&:hover': {
        backgroundColor: active ? '#09042B' : '#09042B',
        color: '#FFF',
    },
    flex: 1,
    ...(active ? { backgroundColor: '#09042B' } : {}),
});

const TabGroup = ({ tabs = [], updateState, state, subMenu, toggleSubMenu, type = 'users', memberships = [] }) => (
    <>
        <Box
            sx={{
                overflow: 'hidden',
                backgroundColor: '#FFF',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                flex: 1,
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'space-between',
                display: 'flex',
                paddingBottom: '30px',
                position: 'relative',
                zIndex: 999,
            }}
        >
            {tabs.map((item) => (
                <Button
                    sx={tabButton(state.activeTabGroup === item.id)}
                    onMouseOver={() => toggleSubMenu({ show: true, type: item.id })}
                >
                    <i
                        sx={{
                            fontSize: 18,
                            mr: 15,
                            ml: 20,
                            color: state.activeTabGroup === item.id ? 'white' : 'black',
                            mt: '-5px',
                        }}
                        className={item.icon}
                    />{' '}
                    {item.label}
                </Button>
            ))}
        </Box>
        {subMenu?.show && (
            <Flex
                sx={{
                    position: 'absolute',
                    width: '900px',
                    minHeight: '100px',
                    pb: '40px',
                    top: '0px',
                    boxShadow: '0px 0px 18px rgba(0,0,0,0.2)',
                    zIndex: 9999,
                    borderRadius: 10,
                    transition: 'all 0.3s linear',
                    cursor: 'pointer',
                    backgroundColor: '#FFF',
                }}
                onMouseLeave={() => {
                    toggleSubMenu({ show: false, type: null });
                }}
            >
                <Box
                    sx={{
                        overflow: 'hidden',
                        backgroundColor: '#FFF',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        flex: 1,
                        width: '100%',
                        paddingBottom: '30px',
                        position: 'relative',
                        zIndex: 99999,
                    }}
                >
                    {type === 'users' && (
                        <UserDetailsOptions
                            state={state}
                            updateState={updateState}
                            subMenuType={subMenu?.type}
                            toggleSubMenu={toggleSubMenu}
                            memberships={memberships}
                        />
                    )}
                </Box>
            </Flex>
        )}
    </>
);

export default TabGroup;
