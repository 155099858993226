/* eslint-disable no-param-reassign */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Divider, Flex, Input, Paragraph } from 'theme-ui';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';

const loadPricing = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { prices },
        } = await axios.get(`${process.env.REACT_APP_USER_DATA_API}/admin/pricing`);

        updateState({
            ...state,
            loading: false,
            ...prices.reduce((accum, item) => {
                accum[`${item.product}_${item.currency}_buy`] = item.buy;
                accum[`${item.product}_${item.currency}_buy_fee_pct`] = item.buyFeePct;
                accum[`${item.product}_${item.currency}_sell`] = item.sell;
                accum[`${item.product}_${item.currency}_sell_fee_pct`] = item.sellFeePct;
                return accum;
            }, {}),
        });
    } catch (e) {
        console.log(e);
    }
};

const update = async (state, updateState, product, currency) => {
    try {
        updateState({ ...state, loading: true });

        const { data: pricing } = await axios({
            url: `${process.env.REACT_APP_USER_DATA_API}/admin/pricing`,
            method: 'PUT',
            data: {
                updates: [
                    {
                        currency,
                        product,
                        buy: state[`${product}_${currency}_buy`],
                        buy_fee_pct: state[`${product}_${currency}_buy_fee_pct`],
                        sell: state[`${product}_${currency}_sell`],
                        sell_fee_pct: state[`${product}_${currency}_sell_fee_pct`],
                    },
                ],
            },
        });

        updateState({
            ...state,
            pricing,
        });
    } catch (e) {
        console.log(e);
    }
};

const Pricing = ({ logOut }) => {
    const [showMenu, toggleMenu] = useState(true);
    const [state, updateState] = useState({});

    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                await loadPricing(state, updateState);
            } catch (e) {
                history.push('/');
            }
        })();
    }, []);

    return (
        <Flex
            sx={{
                overflow: 'hidden',
                top: '0',
                left: '0',
                bottom: 0,
                minHeight: '100vh',
                width: '100vw',
                flexDirection: 'row',
                backgroundColor: 'greyBackground',
                margin: 0,
                padding: 0,
            }}
        >
            <Sidebar
                logout={logOut}
                currentPage="Pricing"
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                history={history}
            />

            <Flex
                sx={{
                    border: '0px red solid',
                    width: '90%',
                    maxWidth: '900px',
                    minHeight: '400px',
                    flexDirection: 'column',
                    mt: '50px',
                    mb: '100px',
                    ml: showMenu ? 240 : 45,
                }}
            >
                {state.loading && <Spinner />}

                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: '20px', fontWeight: '600', fontSize: '21px' }}> Pricing</Paragraph>

                    <Flex sx={{ flexDirection: 'column', backgroundColor: 'white', padding: 40, borderRadius: 12 }}>
                        {[
                            { key: 'solar', title: 'Solar' },
                            { key: 'wind', title: 'Wind' },
                            { key: 'forestry', title: 'Forestry' },
                        ].map((item) => (
                            <Flex sx={{ flexDirection: 'column', mb: 60 }}>
                                <Paragraph sx={{ fontWeight: '800', mt: 10, fontSize: 24 }}>{item.title}</Paragraph>

                                <Divider sx={{ mb: 20, width: 600 }} />

                                <Flex>
                                    <Flex sx={{ flexDirection: 'column' }}>
                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13 }}>
                                            Gbp BUY Price
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({ ...state, [`${item.key}_gbp_buy`]: e.target.value })
                                            }
                                            value={state[`${item.key}_gbp_buy`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13, mt: 10 }}>
                                            Gbp BUY Fee Pct
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({
                                                    ...state,
                                                    [`${item.key}_gbp_buy_fee_pct`]: e.target.value,
                                                })
                                            }
                                            value={state[`${item.key}_gbp_buy_fee_pct`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13, mt: 10 }}>
                                            Gbp SELL Price
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({ ...state, [`${item.key}_gbp_sell`]: e.target.value })
                                            }
                                            value={state[`${item.key}_gbp_sell`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13, mt: 10 }}>
                                            Gbp SELL Fee Pct
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({
                                                    ...state,
                                                    [`${item.key}_gbp_sell_fee_pct`]: e.target.value,
                                                })
                                            }
                                            value={state[`${item.key}_gbp_sell_fee_pct`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Button
                                            onClick={() => update(state, updateState, item.key, 'gbp')}
                                            sx={{ mt: 20, width: 200 }}
                                        >
                                            Update
                                        </Button>
                                    </Flex>

                                    <Flex sx={{ flexDirection: 'column', ml: 150 }}>
                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13 }}>
                                            Eur BUY Price
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({
                                                    ...state,
                                                    [`${item.key}_eur_buy`]: e.target.value,
                                                })
                                            }
                                            value={state[`${item.key}_eur_buy`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13, mt: 10 }}>
                                            Eur BUY Fee Pct
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({
                                                    ...state,
                                                    [`${item.key}_eur_buy_fee_pct`]: e.target.value,
                                                })
                                            }
                                            value={state[`${item.key}_eur_buy_fee_pct`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13, mt: 10 }}>
                                            Eur SELL Price
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({
                                                    ...state,
                                                    [`${item.key}_eur_sell`]: e.target.value,
                                                })
                                            }
                                            value={state[`${item.key}_eur_sell`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Paragraph sx={{ fontWeight: '800', mb: '5px', fontSize: 13, mt: 10 }}>
                                            Eur SELL Fee Pct
                                        </Paragraph>
                                        <Input
                                            onChange={(e) =>
                                                updateState({
                                                    ...state,
                                                    [`${item.key}_eur_sell_fee_pct`]: e.target.value,
                                                })
                                            }
                                            value={state[`${item.key}_eur_sell_fee_pct`]}
                                            sx={{ borderRadius: 12, border: '1px solid #ccc', width: 200 }}
                                        />

                                        <Button
                                            onClick={() => update(state, updateState, item.key, 'eur')}
                                            sx={{ mt: 20, width: 200 }}
                                        >
                                            Update
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
    updateState: (usersListState) => dispatch({ type: 'SET_USERS_STATE', usersListState }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
