/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import { Auth } from 'aws-amplify';

export const search = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true });

    try {
        let emailFilter = '';

        if (currentState?.filters?.email) {
            emailFilter = `&email=${encodeURIComponent(currentState?.filters?.email)}`;
        }
        const { data } = await axios({
            url: `${process.env.REACT_APP_USER_DATA_API}/admin/users?limit=10&pagination_token=${
                currentState.paginationMap[currentState.pageNumber]
            }${emailFilter}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        });

        const { paginationMap } = currentState;

        paginationMap[currentState.pageNumber + 1] = data.pagination_token;

        updateState({
            ...currentState,
            error: '',
            loading: false,
            users: data.users,
            paginationMap,
        });
    } catch (e) {
        if (e?.response?.data?.message === 'Disallowed' || e?.response?.status === 401) {
            try {
                await Auth.signOut();
            } catch (ex) {
                console.log('swallow');
            }
            return window.location.reload();
        }
        updateState({
            ...currentState,
            error: 'Uh Oh, there has been a problem',
            loading: false,
        });
    }
};

export const searchForCorporates = async (currentState, updateState) => {
    updateState({ ...currentState, loading: true });

    try {
        let emailFilter = '';

        if (currentState?.filters?.email) {
            emailFilter = `&email=${encodeURIComponent(currentState?.filters?.email)}`;
        }
        const { data } = await axios({
            url: `${process.env.REACT_APP_USER_DATA_API}/corporates?limit=10&pagination_token=${
                currentState.paginationMap[currentState.pageNumber]
            }${emailFilter}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        });

        const { paginationMap } = currentState;

        paginationMap[currentState.pageNumber + 1] = data.pagination_token;

        updateState({
            ...currentState,
            error: '',
            loading: false,
            corporates: data.corporates,
            paginationMap,
        });
    } catch (e) {
        if (e?.response?.data?.message === 'Disallowed' || e?.response?.status === 401) {
            try {
                await Auth.signOut();
            } catch (ex) {
                console.log('swallow');
            }
            return window.location.reload();
        }
        updateState({
            ...currentState,
            error: 'Uh Oh, there has been a problem',
            loading: false,
        });
    }
};
