/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Amplify } from 'aws-amplify';
import AppWrapper from './components/app-wrapper';
import theme from './theme';
import reducers from './store/reducers';
import { rootSaga } from './store/sagas/index';
import LogIn from './pages/log-in';
import Users from './pages/users';
import Pricing from './pages/pricing';
import { interceptor } from './utils/axios';
import { getMemberships } from './utils/cognito';
import UserDetail from './pages/user-detail';
import Corporates from './pages/corporates';

Amplify.configure({
    aws_project_region: 'eu-west-1',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_USERS_API,
    oauth: {},
});

const sagaMiddleware = createSagaMiddleware();

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composer(applyMiddleware(sagaMiddleware));
const store = createStore(reducers, enhancers);
sagaMiddleware.run(rootSaga);
interceptor(store);

(async () => {
    try {
        const memberships = await getMemberships();
        store.dispatch({ type: 'SET_MEMBERSHIPS', memberships });
    } catch (e) {}
})();

const BackOffice = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <AppWrapper>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <LogIn />
                        </Route>
                        <Route exact path="/pricing">
                            <Pricing />
                        </Route>
                        <Route exact path="/users">
                            <Users />
                        </Route>
                        <Route exact path="/corporates">
                            <Corporates />
                        </Route>
                        <Route exact path="/users/:id">
                            <UserDetail />
                        </Route>
                        <Route path="*">{() => <p>Insert A 404 Page ... </p>}</Route>
                    </Switch>
                </Router>
            </AppWrapper>
        </ThemeProvider>
    </Provider>
);

export default BackOffice;
