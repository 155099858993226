/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { Auth } from "aws-amplify";

export const interceptor = (store: any) => {
  axios.interceptors.request.use(
    async (config) =>
      new Promise((resolve) => {
        if (
          config?.url?.includes("s3.eu-west-1") ||
          config?.url?.includes("s3.us-east-1")
        ) {
          return resolve(config);
        }

        Auth.currentSession()
          .then((session) => {
            const idTokenExpire = session.getIdToken().getExpiration();
            const refreshToken = session.getRefreshToken();
            const currentTimeSeconds = Math.round(+new Date() / 1000);

            if (idTokenExpire < currentTimeSeconds + 30) {
              Auth.currentAuthenticatedUser().then((res) => {
                res.refreshSession(refreshToken, async (err: any) => {
                  if (err) {
                    store && store.dispatch({ type: "LOGOUT" });
                    try {
                      await Auth.signOut();
                    } catch (e) {
                      console.log("Failed to call cognito");
                    }
                    window.location.reload();
                  } else {
                    config.headers.authorization = session
                      .getAccessToken()
                      .getJwtToken();

                    resolve(config);
                  }
                });
              });
            } else {
              config.headers.authorization = session
                .getAccessToken()
                .getJwtToken();

              resolve(config);
            }
          })
          .catch(() => {
            // No logged-in user: don't set auth header
            resolve(config);
          });
      })
  );
};
