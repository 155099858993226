/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import { Box, Flex } from 'theme-ui';
import { Title } from './table';
import Item from './user-data-item';

export const ucase = (text) => (text ? `${text?.substring(0, 1).toUpperCase()}${text?.substring(1)}` : '');

export const WalletBalances = ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="Wallet Balances" state={state} updateState={updateState} type="basic" showEditIcon={false} />

        <Flex
            sx={{
                mt: '40px',
                mb: '0',
                width: '550px',
                justifyContent: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Item
                title="GBP Balance"
                value={new Intl.NumberFormat('en-US', {}).format(
                    state?.accountSummary?.deposit_balances?.find((item) => item.currency === 'gbp')?.amount || 0
                )}
                smallWidth
            />
            <Item
                title="EUR Balance"
                value={new Intl.NumberFormat('en-US', {}).format(
                    state?.accountSummary?.deposit_balances?.find((item) => item.currency === 'eur')?.amount || 0
                )}
                smallWidth
            />
        </Flex>

        <Box sx={{ height: '10px' }} />
    </Box>
);

export default ({ state, updateState }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title="Basic Details" state={state} updateState={updateState} type="basic" showEditIcon={false} />

        <Flex
            sx={{
                mt: '40px',
                mb: '0',
                width: '550px',
                justifyContent: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Item title="UserId" value={`${state.user_id || ''}`} />
            <Item
                title="Email"
                value={`${state.corporateRecord ? state.corporateRecord.contact_email : state.email_address || ''}`}
            />

            {state.corporateRecord ? (
                <>
                    <Item title="Telephone number" value={`${state.corporateRecord.contact_telephone_number || ''}`} />
                    <Item title="Contact person" value={`${state.corporateRecord.contact_person || ''}`} />
                    <Item title="Company name" value={`${state.corporateRecord.company_name || ''}`} />
                    <Item
                        title="Created at"
                        value={`${
                            state.created_at
                                ? moment.utc(state.corporateRecord.created_at).format('Do MMMM YYYY HH:mm')
                                : ''
                        }`}
                    />
                </>
            ) : null}
        </Flex>

        <Box sx={{ height: '10px' }} />
    </Box>
);
