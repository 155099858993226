/* eslint-disable react/no-array-index-key */
import { Button, Flex } from 'theme-ui';
import buttonStyle from '../../utils/button-style';

const SubMenuItems = ({ items = [], toggleSubMenu, entity = 'users', uid }) => (
    <Flex sx={{ width: '100%', mt: '20px', pl: '30px', pr: '30px', flexWrap: 'wrap' }}>
        {items.map((item, i) => (
            <a href={`/${entity}/${uid}?activeTabGroup=${item?.activeTabGroup}&activeTab=${item.activeTab}`}>
                <Button
                    key={`btn-${i}`}
                    onClick={() => {
                        toggleSubMenu({ show: false, type: null });
                    }}
                    sx={buttonStyle}
                >
                    {item?.title}
                </Button>
            </a>
        ))}
    </Flex>
);

export default SubMenuItems;
