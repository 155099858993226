/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
import { Paragraph, Flex, Box, Image, Link, Heading } from 'theme-ui';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Input from '../components/form-input';
import Button from '../components/button';
import Spinner from '../components/spinner';

const resetPassword = async (currentState, updateState, history, logIn, logOut) => {
    try {
        updateState({ ...currentState, loading: true, error: null });

        await Auth.forgotPasswordSubmit(
            currentState.username.toLowerCase().trim(),
            currentState.code.trim(),
            currentState.password.trim()
        );

        return await logUserIn(currentState, updateState, history, logIn, logOut);
    } catch (e) {
        return updateState({
            ...currentState,
            loading: false,
            error: 'We are currently unable to set your password, please ensure the password is at least 10 characters, has a lower and upper case character, a digit and a symbol',
        });
    }
};

const logUserIn = async (currentState, updateState, history, logIn) => {
    try {
        updateState({ ...currentState, loading: true, error: null });

        const user = await Auth.signIn(currentState.username.toLowerCase().trim(), currentState.password.trim());

        if (user?.challengeName === 'SMS_MFA' || user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
            return updateState({
                ...currentState,
                cognitoUser: user,
                challenge: 'mfa',
            });
        }

        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return updateState({
                ...currentState,
                cognitoUser: user,
                error: null,
                challenge: 'new_password',
            });
        }

        logIn([]);

        return history.push('/users');
    } catch (e) {
        if (e.code === 'NotAuthorizedException' || e.code === 'UserNotFoundException') {
            return updateState({
                ...currentState,
                error:
                    e.code === 'NotAuthorizedException'
                        ? e.message
                        : "Please check your credentials, they don't seem to match",
            });
        }
        return updateState({
            ...currentState,
            error: 'Uh oh, something has gone wrong',
        });
    }
};

const confirmSignIn = async (state, updateState, login, history) => {
    try {
        updateState({ ...state, loading: true, error: null });
        await Auth.confirmSignIn(state.cognitoUser, state.code, state.cognitoUser?.challengeName);

        login([]);

        history.push('/users');
    } catch (e) {
        let error = 'We are currently unable to log you in, please try again later';
        if (e.code === 'NotAuthorizedException') {
            error = 'Your session has expired';
        } else if (e.code === 'CodeMismatchException') {
            error = 'The code entered does not match';
        } else if (e.code === 'ExpiredCodeException') {
            error = 'The code has already been used once';
        }
        updateState({ ...state, loading: false, error });
    }
};

const setNewPassword = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        await Auth.completeNewPassword(state.cognitoUser, state.newPassword, {
            given_name: 'unused',
            family_name: 'unused',
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            cognitoUser: null,
            challenge: null,
        });
    } catch (e) {
        let error =
            'We are currently unable to set your password, please ensure the password is at least 10 characters, has a lower and upper case character, a digit and a symbol';
        if (e.code === 'NotAuthorizedException') {
            error = 'Your session has expired';
        } else if (e.code === 'CodeMismatchException') {
            error = 'The code entered does not match the one we sent';
        }
        updateState({ ...state, loading: false, error });
    }
};

const LogIn = ({ logIn, logOut }) => {
    const [currentState, updateState] = useState({
        username: '',
        password: '',
        cognitoUser: null,
        code: '',
    });
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();

                history.push('/users');
            } catch (e) {}
        })();
    }, []);

    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
            }}
        >
            {currentState.loading && <Spinner />}

            <Flex sx={{ width: '480px', flexDirection: 'column' }}>
                <Box sx={{ margin: '30px auto' }}>
                    <Image src="https://cdn.accru.finance/logos/lce/carbon-logo-light6.png" />
                </Box>

                {currentState.cognitoUser && currentState.challenge === 'mfa' && (
                    <Flex sx={{ justifyContent: 'center', flexDirection: 'column' }}>
                        <Heading sx={{ mt: 50, alignSelf: ['center', 'flex-start'] }}>
                            {currentState.cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA'
                                ? 'Enter the code from your authenticator app'
                                : 'Enter the code we have sent to your phone'}
                        </Heading>
                        <Flex
                            sx={{
                                mb: 0,
                                mt: 4,
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Input
                                sx={{
                                    mb: 25,
                                    alignSelf: 'center',
                                    width: 400,
                                    background: '#FFFFFF',
                                }}
                                type="code"
                                id="code"
                                data-testid="code"
                                name="email"
                                label=""
                                placeholder="Enter the 6 digit code"
                                onChange={(e) => updateState({ ...currentState, code: e.target.value })}
                                error={null}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        confirmSignIn(currentState, updateState, logIn, history, logOut);
                                    }
                                }}
                            />
                            <Button
                                variant="primary-wide"
                                sx={{ alignSelf: 'center' }}
                                data-testid="submit"
                                onClick={() => confirmSignIn(currentState, updateState, logIn, history, logOut)}
                            >
                                Submit
                            </Button>
                            <Link
                                onClick={() =>
                                    updateState({
                                        ...currentState,
                                        cognitoUser: null,
                                        error: null,
                                    })
                                }
                                sx={{ textAlign: 'center', mt: 20 }}
                            >
                                Cancel
                            </Link>
                        </Flex>
                    </Flex>
                )}

                {currentState.cognitoUser && currentState.challenge === 'new_password' && (
                    <Flex sx={{ justifyContent: 'center', flexDirection: 'column' }}>
                        <Heading sx={{ mt: 50, alignSelf: 'center' }}>Please set a new password</Heading>
                        <Flex
                            sx={{
                                mb: 0,
                                mt: 4,
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Input
                                sx={{
                                    mb: 25,
                                    alignSelf: 'center',
                                    width: 400,
                                    background: '#FFFFFF',
                                }}
                                type="password"
                                id="password"
                                data-testid="password"
                                name="password"
                                label=""
                                placeholder="Enter your new password"
                                onChange={(e) => updateState({ ...currentState, newPassword: e.target.value })}
                                error={null}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setNewPassword(currentState, updateState);
                                    }
                                }}
                            />
                            <Button
                                variant="primary-wide"
                                sx={{ alignSelf: 'center' }}
                                data-testid="submit"
                                onClick={() => setNewPassword(currentState, updateState)}
                            >
                                Submit
                            </Button>
                            <Link
                                onClick={() =>
                                    updateState({
                                        ...currentState,
                                        cognitoUser: null,
                                        error: null,
                                    })
                                }
                                sx={{ textAlign: 'center', mt: 20 }}
                            >
                                Cancel
                            </Link>
                        </Flex>
                    </Flex>
                )}

                {!currentState.cognitoUser && currentState.challenge !== 'reset_password' && (
                    <>
                        <Paragraph sx={{ fontSize: 25, textAlign: 'center' }}>Welcome back</Paragraph>

                        <Box sx={{ margin: '50px auto 60px', width: '90%' }}>
                            <Input
                                type="email"
                                id="email"
                                data-testid="email"
                                name="email"
                                placeholder="Enter your email address"
                                onChange={(e) => updateState({ ...currentState, username: e.target.value })}
                                value={currentState.username}
                            />
                            <Input
                                type="password"
                                id="password"
                                data-testid="password"
                                name="password"
                                placeholder="password"
                                onChange={(e) => updateState({ ...currentState, password: e.target.value })}
                                value={currentState.password}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        logUserIn(currentState, updateState, history, logIn, logOut);
                                    }
                                }}
                            />

                            <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 20 }}>
                                <Button
                                    variant="primary-wide"
                                    data-testid="login"
                                    onClick={() => logUserIn(currentState, updateState, history, logIn, logOut)}
                                >
                                    Log in
                                </Button>
                                <Link
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            cognitoUser: null,
                                            error: null,
                                            challenge: 'reset_password',
                                        })
                                    }
                                    sx={{ textAlign: 'center', mt: 20 }}
                                >
                                    Reset password
                                </Link>
                                <Paragraph sx={{ mt: 50, color: 'green' }}>
                                    You must be on the VPN in production!
                                </Paragraph>
                            </Flex>
                        </Box>
                    </>
                )}
                {!currentState.cognitoUser && currentState.challenge === 'reset_password' && (
                    <>
                        <Paragraph sx={{ fontSize: 25, textAlign: 'center' }}>Reset password</Paragraph>

                        <Box sx={{ margin: '50px auto 60px', width: '90%' }}>
                            <Input
                                type="email"
                                id="email"
                                data-testid="email"
                                name="email"
                                placeholder="Enter your email address"
                                onChange={(e) => updateState({ ...currentState, username: e.target.value })}
                                value={currentState.username}
                            />
                            <Input
                                type="code"
                                id="code"
                                data-testid="code"
                                name="code"
                                placeholder="Enter your code sent via email"
                                onChange={(e) => updateState({ ...currentState, code: e.target.value })}
                                value={currentState.code}
                            />
                            <Input
                                type="password"
                                id="password"
                                data-testid="password"
                                name="password"
                                placeholder="Your new password"
                                onChange={(e) => updateState({ ...currentState, password: e.target.value })}
                                value={currentState.password}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        resetPassword(currentState, updateState, history, logIn, logOut);
                                    }
                                }}
                            />

                            <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: 20 }}>
                                <Button
                                    variant="primary-wide"
                                    data-testid="login"
                                    onClick={() => resetPassword(currentState, updateState, history, logIn, logOut)}
                                >
                                    Reset
                                </Button>
                                <Link
                                    onClick={() =>
                                        updateState({
                                            ...currentState,
                                            cognitoUser: null,
                                            error: null,
                                            challenge: null,
                                        })
                                    }
                                    sx={{ textAlign: 'center', mt: 20 }}
                                >
                                    Cancel
                                </Link>
                            </Flex>
                        </Box>
                    </>
                )}
                {currentState.error && (
                    <Paragraph
                        sx={{
                            color: 'red',
                            alignSelf: 'center',
                            mt: 20,
                            textAlign: 'center',
                        }}
                    >
                        {currentState.error}
                    </Paragraph>
                )}
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch) => ({
    logIn: (memberships) => dispatch({ type: 'LOG_USER_IN', memberships }),
    logOut: () => dispatch({ type: 'LOG_USER_OUT' }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return { loggedIn: account.loggedIn, memberships: account.memberships };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
