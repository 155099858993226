/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { Box, Flex } from 'theme-ui';
import { Title } from './table';
import Item from './user-data-item';

export const ucase = (text) => (text ? `${text?.substring(0, 1).toUpperCase()}${text?.substring(1)}` : '');

export const CarbonCreditTypeSummary = ({ state, title, updateState, product }) => (
    <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
        <Title title={title} state={state} updateState={updateState} type="basic" showEditIcon={false} />

        <Flex
            sx={{
                mt: '40px',
                mb: '0',
                width: '550px',
                justifyContent: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <Item
                title="Total credits"
                empty
                value={state?.accountSummary?.investment_totals?.find((item) => item.product === product)?.amount || 0}
                smallWidth
            />
            <Item
                title="PnL (GBP)"
                empty
                value={new Intl.NumberFormat('en-US', {}).format(
                    state?.accountSummary?.investment_totals?.find((item) => item.product === product)?.pnl?.gbp || 0
                )}
                smallWidth
            />
            <Item
                title="PnL (EUR)"
                empty
                value={new Intl.NumberFormat('en-US', {}).format(
                    state?.accountSummary?.investment_totals?.find((item) => item.product === product)?.pnl?.eur || 0
                )}
                smallWidth
            />
        </Flex>

        <Box sx={{ height: '10px' }} />
    </Box>
);

export default ({ state, updateState }) => {
    console.log(state);
    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Box sx={{ width: '100%', marginLeft: '15px', mb: 40 }}>
                <Title title="Totals" state={state} updateState={updateState} type="basic" showEditIcon={false} />

                <Flex
                    sx={{
                        mt: '40px',
                        mb: '0',
                        width: '550px',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                    }}
                >
                    <Item
                        title="Total credits"
                        empty
                        value={`${state?.accountSummary?.totals?.total_credits || 0}`}
                        smallWidth
                    />
                    <Item
                        title="GBP Valuation"
                        empty
                        value={new Intl.NumberFormat('en-US', {}).format(`${state?.accountSummary?.totals?.gbp || 0}`)}
                        smallWidth
                    />
                    <Item
                        title="EUR Valuation"
                        empty
                        value={new Intl.NumberFormat('en-US', {}).format(`${state?.accountSummary?.totals?.eur || 0}`)}
                        smallWidth
                    />
                </Flex>

                <Box sx={{ height: '10px' }} />
            </Box>

            <CarbonCreditTypeSummary
                state={state}
                updateState={updateState}
                title="Forestry Credits"
                product="forestry"
            />

            <CarbonCreditTypeSummary state={state} updateState={updateState} title="Wind Credits" product="wind" />

            <CarbonCreditTypeSummary state={state} updateState={updateState} title="Solar Credits" product="solar" />
        </Flex>
    );
};
